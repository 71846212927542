import React, {useContext, useState} from 'react';
import './home.css';
import {Link} from 'react-router-dom';
import {AnimatePresence, motion} from 'framer-motion';
import {PopupContext} from '../backend/authProvider';

const Home = (props) => {
	document.querySelector('meta[name="theme-color"]').setAttribute('content', '#e63946');

	const [clickCount, setClickCount] = useState(0);
	const {setShowPopup, setText, setTime} = useContext(PopupContext);

	const handleEasterEgg = () => {
		if (clickCount < 2) {
			setClickCount(clickCount + 1);
		} else {
			setClickCount(0);
			setText('Drinks Away was made with ❤ by your boys Nic, Alex and Anthony. Cheers! 🍻');
			setTime(7000);
			setShowPopup(true);
		}
	};

	return (
		<div className="app-container-home">
			<div className="grid">
				<motion.div
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					transition={{duration: 0.1}}
					className="header">
					<motion.h1 onClick={handleEasterEgg} whileTap={{scale: 0.9}} className="title">DRINKS <br/>AWAY
					</motion.h1>
				</motion.div>
				<motion.div
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					transition={{duration: 0.2}}
					className="subHeader">
					<p>THE BEST DRINKING GAME FOR WHEN YOU’RE FAR APART BUT WANT TO FEEL CLOSE</p>
				</motion.div>

				{/*links to host and join page*/}

				<motion.div
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					transition={{delay: 0.5, duration: 0.8}}
					className="utilities">
					<motion.button onClick={() => props.history.push('/settings')} className="utilities-button">
						<ion-icon name="cog-outline"></ion-icon>
					</motion.button>
					<motion.button onClick={() => props.history.push('/leaderboard')} className="utilities-button">
						<ion-icon name="podium-outline"></ion-icon>
					</motion.button>
					<motion.button onClick={() => props.history.push('/info')} className="utilities-button">
						<ion-icon name="information-circle-outline"></ion-icon>
					</motion.button>
				</motion.div>

				<AnimatePresence>
					<motion.div
						className="buttons"
						initial={{opacity: 0}}
						animate={{opacity: 1}}
						transition={{duration: 0.8}}
					>
						<motion.button
							className="link1"
							onClick={() => props.history.push('/host')}>
							HOST A GAME
						</motion.button>
						<Link className="link2" to='/join'>
							JOIN A GAME
						</Link>
					</motion.div>
				</AnimatePresence>
			</div>
		</div>
	);
};

export default Home;

import React, { useContext, useState } from 'react';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { Link, Redirect } from 'react-router-dom';
import './registration.css';
import {
	getRedirectResult,
	googleSignIn,
	signInWithEmailAndPass,
	signUpWithEmailAndPass,
	resetPassword,
} from '../backend/authentication';
import { PopupContext } from '../backend/authProvider';

const Registration = (props) => {
	document.querySelector('meta[name="theme-color"]').setAttribute('content', '#e63946');

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showContent, setShowContent] = useState(true);
	const { setShowPopup, setText } = useContext(PopupContext);
	const FINE_PRINT = 'Drinks Away won\'t sell your data. An account is needed to track your drinks and rankings for the leaderboards.';

	getRedirectResult(setShowPopup, setText);

	const resetPass = () => {
		if (email.length < 1) {
			setText('Please enter your email to reset your password.');
			setShowPopup(true);
		}

		resetPassword(email, setShowPopup, setText);
	};

	const signIn = () => {
		signInWithEmailAndPass({ email: email, password: password }, setShowPopup, setText);
	};

	const signUp = () => {
		signUpWithEmailAndPass({ email: email, password: password }, setShowPopup, setText);
	};

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	const emailChangeHandler = (e) => {
		setEmail(e.target.value);
	};

	const passwordChangeHandler = (e) => {
		setPassword(e.target.value);
	};

	return props.currentUser ? <Redirect to="/sign-in-success"/> : (
		<div className="app-container-registration">
			<div className="grid-reg">
				<div className="header-reg">
					<Link to="/">
						<motion.p whileTap={{scale: 0.9}} className="title-reg">DRINKS<br/>AWAY</motion.p>
					</Link>
				</div>
				<div className="subHeader-reg">
					THE BEST DRINKING GAME FOR WHEN YOU’RE FAR APART BUT WANT TO FEEL CLOSE
				</div>
				{/*Sign in grid*/}
				<AnimateSharedLayout>
					{showContent && (
						<motion.div
							initial={{opacity: 1, x: -300}}
							animate={{opacity: 1, x: 0}}
							exit={{opacity: 1, x: -300}}
							className="sign-in-grid">
							<p id="sign-in-header">SIGN IN</p>

							<div className="sign-in-prompt">
								<motion.input
									onChange={emailChangeHandler}
									whileTap={{scale: 0.9}}
									className="sign-in-input" type="email"
									placeholder="Enter your email:"/>


								<motion.input
									onChange={passwordChangeHandler}
									whileTap={{scale: 0.9}}
									className="sign-in-input" type="password"
									placeholder="Enter your password:"/>

								<motion.div id="sign-in-box">
									<motion.button className="sign-in" whileTap={{scale: 0.9}} onClick={() => googleSignIn()}>
										<p className="button-text">Join with</p>
										<ion-icon size="large" name="logo-google">
										</ion-icon>
									</motion.button>
									<motion.button onClick={() => signIn()} className="sign-in" whileTap={{scale: 0.9}}>
										<p className="button-text">SIGN IN</p>
										<ion-icon size="large" name="chevron-forward">
										</ion-icon>
									</motion.button>
								</motion.div>
							</div>
							<div className="route-signup">
								<p className="toggler-text">Don&lsquo;t have an account?</p>
								<button onClick={() => toggleContent()} className="sign-up">Sign up</button>
								<p className="toggler-text">Forgot password?</p>
								<button onClick={() => resetPass()} className="sign-up">Reset it</button>
								<p className="fine-print">{FINE_PRINT}</p>
							</div>

						</motion.div>
					)}

					{/*Sign up grid*/}

					{!showContent && (
						<motion.div
							initial={{opacity: 1, x: 300}}
							animate={{opacity: 1, x: 0}}
							exit={{opacity: 1, x: 300}}
							className="sign-up-grid">
							<p id="sign-in-header">SIGN UP</p>

							<div className="sign-in-prompt">
								<motion.input
									onChange={emailChangeHandler}
									whileTap={{scale: 0.9}}
									className="sign-in-input" type="email"
									placeholder="Enter your email:"/>

								<motion.input
									onChange={passwordChangeHandler}
									whileTap={{scale: 0.9}}
									className="sign-in-input" type="password"
									placeholder="Enter your password:"/>

								<motion.div id="sign-in-box">
									<motion.button onClick={() => googleSignIn()} className="sign-in" whileTap={{scale: 0.9}}>
										<p className="button-text">Join with</p>
										<ion-icon size="large" name="logo-google">
										</ion-icon>
									</motion.button>
									<motion.button onClick={() => signUp()} className="sign-in" whileTap={{scale: 0.9}}>
										<p className="button-text">SIGN UP</p>
										<ion-icon size="large" name="chevron-forward">
										</ion-icon>
									</motion.button>
								</motion.div>
							</div>
							<div className="route-signup">
								<p className="toggler-text">Already have an account?</p>
								<button onClick={() => toggleContent()} className="sign-up">Sign in</button>
								<p className="fine-print">{FINE_PRINT}</p>
							</div>

						</motion.div>
					)}
				</AnimateSharedLayout>
			</div>
		</div>
	);
};

export default Registration;

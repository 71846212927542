import React, {useContext, useState} from 'react';
import './join.css';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';
import {AuthContext, PopupContext} from '../backend/authProvider';

const Join = (props) => {
	document.querySelector('meta[name="theme-color"]').setAttribute('content', '#FAF9F9');

	const [roomCode, setRoomCode] = useState('');
	const {setShowPopup, setText} = useContext(PopupContext);
	const {gameName, setGameName} = useContext(AuthContext);

	const handleNameInputChange = (e) => {
		setGameName(e.target.value);
	};

	const handleRoomCodeInputChange = (e) => {
		setRoomCode(e.target.value.toString());
	};

	const confirmCodeIsAllDigits = (code) => {
		return /^\d+$/.test(code);
	};

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const joinGame = () => {
		if (gameName === null || gameName.length < 1) {
			setText('Maybe you should write a name so we know who you are...');
			setShowPopup(true);
			return;
		}
		if (roomCode.length != 4 || !confirmCodeIsAllDigits(roomCode)) {
			setText('You kinda need a 4 digit code to join...');
			setShowPopup(true);
			return;
		}

		setGameName(capitalizeFirstLetter(gameName));
		props.history.push('/game/' + roomCode);
	};

	return (
		<div className="app-container-join">
			<motion.div className="grid-join">
				<motion.div
					initial={{opacity: 0}}
					animate={{scale: 1.1, opacity: 1}}
					transition={{duration: 1}}
					className="header-flex">
					<Link to='/' className="back-join">
						<ion-icon name="arrow-back-outline">
						</ion-icon>
					</Link>
					<motion.div className="header-join"
					>
						<p className="join-title">JOIN A GAME</p>
					</motion.div>
				</motion.div>
				<motion.div
					className="join-prompt"
					initial={{opacity: 0, y: 100}}
					animate={{opacity: 1, y: 0}}
				>
					<motion.input
						onChange={handleNameInputChange}
						whileTap={{scale: 0.9}}
						className="join-input"
						type="text"
						placeholder="Enter your name:"/>
					<motion.input
						onChange={handleRoomCodeInputChange}
						whileTap={{scale: 0.9}} maxLength="4"
						className="join-input" type="number"
						placeholder="4 digit room code:"/>
					<motion.div id="join-box" whileTap={{scale: 0.9}}>
						<button onClick={joinGame} className="join">
							JOIN
							<ion-icon size="large" name="chevron-forward">
							</ion-icon>
						</button>

					</motion.div>
				</motion.div>
				<motion.div
					className="instructions"
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					transition={{duration: 4}}
				>
					<p>Enter your name and the four digit number code in the boxes above to join your friends!</p>

				</motion.div>

			</motion.div>
		</div>
	);
};

export default Join;

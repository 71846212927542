import React from 'react';
import './info.css';
import {motion} from 'framer-motion';

const Info = (props) => {
	document.querySelector('meta[name="theme-color"]').setAttribute('content', '#1d3557');

	return (
		<motion.div className="app-container-info">
			<motion.div className="info-grid">
				<motion.div
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					className="info-header">
					<motion.button onClick={() => props.history.push('/')} className="info-to-home">
						<ion-icon name="arrow-back-outline"></ion-icon>
					</motion.button>

					<motion.div className="info-title">
						INFO
					</motion.div>
				</motion.div>
				<motion.div
					initial={{opacity: 0, y:10}}
					animate={{opacity: 1, y:0}}
					className="info-description">
					<motion.h2
						initial={{opacity: 0, y:10}}
						animate={{opacity: 1, y:0}}
						transition={{delay: 0.3}}
						className="info-description-header">
						What is Drinks Away?
					</motion.h2>
					<motion.ul
						initial={{opacity: 0, y:10}}
						animate={{opacity: 1, y:0}}
						transition={{delay: 0.6}}
						className="info-description-list">
						<li className="info-list-item">Drinks Away is a party game to be played in groups of 2 or more.</li>
						<li className="info-list-item">You can either Host a game or Join a game your friend is hosting.</li>
						<li className="info-list-item">Play the game by performing the task(s) written on cards.</li>
						<li className="info-list-item">Click the Thumbs Up button when you&rsquo;re ready to go to the next card. If everyone clicks it, it will automatically switch cards.</li>
						<li className="info-list-item">Click the People button to see where you are in the Virtual Seating.</li>
						<li className="info-list-item">The more you drink, the higher you&rsquo;ll be in the Hall of Drinkers!</li>
					</motion.ul>
				</motion.div>

			</motion.div>
		</motion.div>
	);
};

export default Info;

import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './players.css';

function PlayerList(props) {

	const players = props.player;
	const listOfPlayers = players.map((player) =>
		<motion.div className="players" key={player.toString()}>
			<p>{player}</p>
		</motion.div>
	);
	return (
		<motion.div style={{
			position: 'fixed',
			top: '20%',
			left: '50%',
			transform: 'translate3d(-50%,0,0)'
		}} className="player-grid">
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				className="list-of-players">{listOfPlayers}
			</motion.div>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}>
				<motion.button onClick={() => props.setShowModal(!props.showModal)} className="hide-players">
                    Hide Players
				</motion.button>
			</motion.div>
		</motion.div>
	);
}

export const Players = ({ showModal, setShowModal, players }) => {
	return (
		<AnimatePresence exitBeforeEnter>
			{showModal && (
				<PlayerList player={players} setShowModal={setShowModal} showModal={showModal} />
			)}
		</AnimatePresence>
	);
};

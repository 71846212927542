export const specialCards = {
	cards: [
		{
			text: [
				'You can click the "people" icon below to view your virtual seating arrangement. If playing remotely, this will be useful when playing cards of the "Go around naming..." style.',
				'Remember, getting purple "Full Send" cards will be saved and reflected in the leaderboards! Enjoy! 😄'
			],
		},
		{
			text: [
				'Game on! 🍻 The host can advance to the next card by swiping it to the left, or by clicking the next button.',
				'Another way to advance to the next card is if everyone clicks on the "thumbs-up" icon below to ready-up.'
			],
		},
		{
			text: [
				'Hey! Welcome to Drinks Away! Please wait for the host to start the game.',
				'Have fun, and please remember to drink responsibly! 💜🍺'
			],
		},
		{
			text: [
				'Game over! Thanks for playing Drinks Away 💜! Play again to discover more cards and climb your way to the top of the "Hall of Drinkers" leaderboards! 🍻'
			],
			lastCard: true,
		},
	]
};

export const cardPack = {
	cards: [
		{
			text: [
				'Vote for the player who\'s the most drunk.',
				'That person must drink two times, then have some water. Calm down you crazy.'
			],
		},
		{
			text: [
				'Vote for the person who\'s always lagging out from the video call.',
				'That person must drink 3 times, and get better internet please.'
			],
		},
		{
			text: [
				'People who have their shirts off, take a drink.',
				'Nice tits bro.'
			],
		},
		{
			text: [
				'{{user1}}, choose a word and then everyone must go around saying a word that rhymes with it.',
				'The first one to fail must drink twice.'
			],
		},
		{
			text: [
				'Drink once if you own an iPhone, and please get rid of that shit.'
			],
		},
		{
			text: [
				'Drink once if you own an Android phone, and please stop talking about it.'
			],
		},
		{
			text: [
				'Drink once for each country you\'ve visited.'
			],
		},
		{
			text: [
				'{{user1}}, play the next round with your eyes closed.',
				'If you\'re caught opening your eyes, drink three times.'
			],
		},
		{
			text: [
				'Last one to says \'Cheers!\' must drink once.',
				'Better luck next time, slow-poke.'
			],
		},
		{
			text: [
				'{{user1}}, choose a person who must drink twice with you.',
				'Now get a room, cuties.'
			],
		},
		{
			text: [
				'If you\'re the tallest player, drink once for every inch you are taller than the second tallest player.'
			],
		},
		{
			text: [
				'{{user1}}, tell {{user2}} the ugliest thing about their face.',
				'If everyone decides {{user1}} went easy on {{user2}}, {{user1}} drinks four times.'
			],
		},
		{
			text: [
				'{{user1}}, tell everybody a secret or down your drink.',
				'What happens in Drinks Away, stays in Drinks Away.'
			],
		},
		{
			text: [
				'{{user1}}, do your best Tyrannosaurus Rex impression, and drink 10 times.'
			],
			virus: 8,
		},
		{
			text: [
				'{{user1}}, close your eyes and guess {{user2}}\'s eye color.',
				'If you got it wrong, drink three times.'
			],
		},
		{
			text: [
				'Everyone who likes pineapple on pizza, drink three times.',
				'Then, review your life choices you psycho.'
			],
		},
		{
			text: [
				'{{user1}}, pick a drinking buddy.',
				'When you drink, they drink, and vice-versa.'
			],
		},
		{
			text: [
				'The oldest player drinks once.',
				'The youngest player drinks twice.'
			],
		},
		{
			text: [
				'Play a round of \'Never Have I Ever\', starting with {{user1}}, and loser drinks four times.',
				'Loser should really chill smh.'
			],
		},
		{
			text: [
				'{{user1}}, wear something funky on your head as a hat for the rest of the game.',
				'Everytime you\'re caught with it off, drink three times.'
			],
		},
		{
			text: [
				'{{user1}}, choose someone who must drink three times.',
				'Choose wisely, for revenge is highly likely.'
			],
		},
		{
			text: [
				'{{user1}}, guess when {{user2}} took their last dump.',
				'If you\'re within 3 hours from the correct answer, give out three sips.'
			],
		},
		{
			text: [
				'Anyone with earrings drinks two times.'
			],
		},
		{
			text: [
				'Mini yoga session: everyone breathes deeply for 10 seconds.',
				'You\'re welcome for the little break.'
			],
		},
		{
			text: [
				'Bitcoin is tomorrow\'s money.',
				'Also, everyone drink four times.'
			],
		},
		{
			text: [
				'Everyone vote for who the most sober player is.',
				'They must drink 5 times.'
			],
		},
		{
			text: [
				'The person with the biggest shoe size must drink twice.',
				'Yes, we get it, you have a gigantic 🍆.'
			],
		},
		{
			text: [
				'All virgins drink twice.',
				'So basically, all of you pussies drink 👶.'
			],
		},
		{
			text: [
				'Anyone who watches anime must drink four times.',
				'Lay off the hentai buddy, Tsunade won\'t show you her milkers any time soon.'
			],
		},
		{
			text: [
				'{{user1}} must drink 10 times non-stop.'
			],
			virus: 10,
		},
		{
			text: [
				'The player with the largest ears must drink twice.',
				'Fly away, Dumbo!'
			],
		},
		{
			text: [
				'Every player must read their last text out loud or drink three times.',
				'{{user1}}, you start.'
			],
		},
		{
			text: [
				'Players with receding hairlines must proudly say so and drink once.',
				'If you didn\'t say so and everyone thinks you do, drink four times and buy some Rogaine, Mr. Clean.'
			],
		},
		{
			text: [
				'The players in a relationship drink twice.',
				'We wanna be aunts and uncles soon, so go pop some babies out.'
			],
		},
		{
			text: [
				'Everyone vote who has the best haircut. {{user1}}, you start',
				'The player with the most votes must give out a drink.'
			],
		},
		{
			text: [
				'Vote for who will get married first, that person drinks twice.',
				'If both people in the relationship you voted for are playing, they drink four times each.'
			],
		},
		{
			text: [
				'Blondes drink twice.',
				'You stupid fucks.'
			],
		},
		{
			text: [
				'{{user1}} can pick a word that is banned for two rounds.',
				'If anyone says this word they must drink twice.'
			],
		},
		{
			text: [
				'{{user1}} has to scream \'I LOVE IT IN THE BUTT\'.',
				'If they can\'t for any reason, they drink 5 times.'
			],
		},
		{
			text: [
				'Vote for the person who always runs off when they\'re drunk.',
				'The idiot drinks 4 times.'
			],
		},
		{
			text: [
				'Stay completely still like a statue for one round.',
				'One movement = one sip.'
			],
		},
		{
			text: [
				'Everyone vote which color they prefer, purple or orange.',
				'The side with the least amount of people drinks twice. If it\'s a draw, everyone drinks once.'
			],
		},
		{
			text: [
				'Anyone drinking beer must drink twice.',
				'If it has \'Bud\' in the name, drink another two times.'
			],
		},
		{
			text: [
				'{{user1}}, choose two players who will each drink twice.'
			],
		},
		{
			text: [
				'{{user1}} drink 14 times without using your hands.',
				'Try not to make a mess.'
			],
			virus: 14
		},
		{
			text: [
				'Everyone must say when and how they met {{user1}}. {{user2}}, you start.',
				'Don\'t remember? Drink three times.'
			],
		},
		{
			text: [
				'The next person to laugh must drink twice.',
				'Try not to picture {{user1}} sticking a pineapple inside {{user2}}\'s ass.'
			],
		},
		{
			text: [
				'Play the penis game. {{user1}} starts.',
				'Whoever chickens out first drinks four times.'
			],
		},
		{
			text: [
				'Name the craziest thing you\'ve seen someone else playing this game do.',
				'If you can\'t name anything, drink three times. If you can, drink once.'
			],
		},
		{
			text: [
				'If you would have voted for Trump, drink two times.',
				'Now make your best impersonation of him.'
			],
		},
		{
			text: [
				'Starting with {{user1}}, go around naming Countries in Asia.',
				'The first to repeat one or not be able to think of one must drink three times.'
			],
		},
		{
			text: [
				'Anyone who\'s yaked tonight already, drink some water buddy.',
				'Anyone thinking of tactical puking, just go do it now.'
			],
		},
		{
			text: [
				'All vegetarians/vegans drink twice.',
				'Seems like a huge \'missed steak\'.'
			],
		},
		{
			text: [
				'If you don\'t want to play anymore, drink once.',
				'Also, GTFO, you\'re killing the vibe.'
			],
		},
		{
			text: [
				'{{user1}}, drink fives times if you\'ve seen more than half the players in this game naked.'
			],
		},
		{
			text: [
				'{{user1}} drinks four times.',
				'Because fuck you, that\'s why.'
			],
		},
		{
			text: [
				'If your age is an odd number, drink once.',
				'If your age is an even number, drink twice.'
			],
		},
		{
			text: [
				'Pee break! Everyone politely waits for people who need to go pee.',
				'If you go pee though, drink three times.'
			],
		},
		{
			text: [
				'The next person to mention {{user1}}\'s name, drinks twice.'
			],
		},
		{
			text: [
				'{{user1}}, choose a number from 1 to 5.',
				'Whichever the amount you chose, you sip that many times and give out as many sips.'
			],
		},
		{
			text: [
				'{{user1}}, spell out c-u-n-n-i-l-i-n-g-u-s out loud, and drink after every letter.'
			],
			virus: 11
		},
		{
			text: [
				'Would you rather get rid of One Direction or Justin Bieber?',
				'Everyone vote at the same time, losing group drinks 2 times.'
			],
		},
		{
			text: [
				'The last person to have smoked weed drinks 5 times.',
				'Now\'s also a good time to go get some munchies.'
			],
		},
		{
			text: [
				'Last to say \'Ah shooks! My cock ring slipped off\' drinks three times.'
			],
		},
		{
			text: [
				'{{user1}}, give out three sips.',
				'You can give all three to the super drunk one, just sayin\'.'
			],
		},
		{
			text: [
				'First to guess {{user1}}\'s middle name gives out three sips.',
				'If no one can guess it, everyone drinks twice.'
			],
		},
		{
			text: [
				'Whoever speaks the most languages drinks two times.',
				'Then, give us your best Pitbull singing impersonation, Mr. Worldwide.'
			],
		},
		{
			text: [
				'{{user1}}, give out three sips if you\'ve ever taken a picture with a celebrity.',
				'If you haven\'t, or the others don\'t think that that person is famous enough, drink three times.'
			],
		},
		{
			text: [
				'{{user1}}, tell a joke.',
				'If at least one person laughs, everyone drinks twice.'
			],
		},
		{
			text: [
				'Drink once if you\'ve ever been in a fight.',
				'Drink twice more if you won.'
			],
		},
		{
			text: [
				'{{user1}}, tell a joke.',
				'If no one laughs, then you must drink 3 times.'
			],
		},
		{
			text: [
				'{{user1}} creates a new rule.',
				'Everyone must follow it for the following five rounds, or drink 8 times.'
			],
		},
		{
			text: [
				'Everyone must keep a hand on their crotch for the next round.',
				'Come on, do it, don\'t be shy.'
			],
		},
		{
			text: [
				'Uh-oh! You\'ve encountered a great magical fairy. The fairy tells you it needs your help!',
				'You must follow her back to the shrine and seal the... Ah, fuck it, just drink 12 times, {{user1}}.'
			],
			virus: 12
		},
		{
			text: [
				'{{user1}}, say two truths and a lie about yourself.',
				'Everyone else vote for which is the lie. Those who got it wrong drink three times.'
			],
		},
		{
			text: [
				'Everyone drink three times.'
			],
		},
		{
			text: [
				'People with pink, purple or blue drinks must drink three times.'
			],
		},
		{
			text: [
				'Hey {{user1}}! You doing good buddy? Yeah well, fuck you.',
				'Drink 14 times.'
			],
			virus: 14
		},
		{
			text: [
				'The most jacked player must drink twice.',
				'Everyone votes, but please don\'t let them take off their shirt.'
			],
		},
		{
			text: [
				'{{user1}}, tell us your favorite porn video and drink once.',
				'If you don\'t watch porn, stop lying and drink three times.'
			],
		},
		{
			text: [
				'Drink twice for every other player you\'ve seen naked.',
				'Now drink twice again for every player you\'ve seen naked more than 10 times.'
			],
		},
		{
			text: [
				'If you\'re having a good time, drink once.',
				'If you\'re not, get the fuck out.'
			],
		},
		{
			text: [
				'{{user1}}, drink 5 times.',
				'The next player drinks one time less, and so on until reaching 0.'
			],
		},
		{
			text: [
				'{{user1}}, we rate you a 10/10 😍.',
				'So, drink 10 times you beauty.'
			],
			virus: 10
		},
		{
			text: [
				'Players with beards, drink twice.',
				'Whatever your girlfriends or moms say, don\'t remove it.'
			],
		},
		{
			text: [
				'Brunettes drink twice.',
				'Honestly kind of a boring card, I know, I\'m sorry.'
			],
		},
		{
			text: [
				'It\'s 3:17 AM as I\'m writing this, and I\'ve eaten so many cookies that I think I\'m having a heart attack?',
				'If you\'re playing this game, I\'m still alive, so fucking drink five times 🤪.'
			],
		},
		{
			text: [
				'{{user1}}, choose which player has the nicest smile.',
				'They must drink twice.'
			],
		},
		{
			text: [
				'Drink three times if you\'ve ever been arrested.'
			],
		},
		{
			text: [
				'{{user1}} and {{user2}}, decide which of you has drank more in their entire life.',
				'The one who\'s drank the least drinks five times.'
			],
		},
		{
			text: [
				'Starting with {{user1}}, go around the room naming beer companies.',
				'First to fail or repeat a company drinks three times.'
			],
		},
		{
			text: [
				'{{user1}}, drink three times if you\'re a human being.',
				'Can\'t wait for this game to become so popular it goes inter-galactic and this question becomes relevant.'
			],
		},
		{
			text: [
				'{{user1}}, give out as many sips as you want (up to 5).',
				'Small catch: you also need to drink them.'
			],
		},
		{
			text: [
				'The most Québécois player drinks five times.',
				'Eeeeeenweille Keven, continue comme ça.'
			],
		},
		{
			text: [
				'{{user1}}, drink 12 times. You may also give out 12 sips.',
				'Yes, I am a benevolent God.'
			],
			virus: 12
		},
		{
			text: [
				'{{user1}} name your best friend within the players, and you both drink twice.',
				'If you don\'t want to reveal your best friend, everyone drinks three times.'
			],
		},
		{
			text: [
				'{{user1}}, drink as much as the person who drinks the most on the next card.',
				'Goodluck, also remember: there is no cake, the cake is a lie 🎂.'
			],
		},
		{
			text: [
				'{{user1}}, drink as many times as you\'d like.',
				'{{user2}}, have five more sips than that.'
			],
		},
		{
			text: [
				'Go around naming pet peeves, {{user1}} you start. People called out for doing the pet peeve drink twice.',
				'My pet peeve is people who don\'t answer their phones or leave you on seen, if that\'s you, drink 5 times.'
			],
		},
		{
			text: [
				'{{user1}}, name who\'s most likely to shit n\' shower (poop, no wipe, and take a shower right away).',
				'That person must drink four times, and wash their goddamn hands 💩.'
			],
		},
		{
			text: [
				'{{user1}}, quote a movie.',
				'If the player before you cannot guess the movie, they must drink three times.'
			],
		},
		{
			text: [
				'Starting with {{user1}}, go around naming \'things in the kitchen you can use for sex\'.',
				'First to fail or repeat an item drinks three times.'
			],
		},
		{
			text: [
				'TACTICAL ☣NUKE☣ INCOMING.',
				'{{user1}}, drink 25 times to protect yourself from the radiation.'
			],
			virus: 25
		},
		{
			text: [
				'If you\'re a spitter, drink four times.',
				'If you\'re a winner, only drink once.'
			],
		},
		{
			text: [
				'Dog owners drink twice.',
			],
		},
		{
			text: [
				'Starting with {{user1}}, everyone go around naming Countries in Europe.',
				'The first person to repeat one or not be able to think of one must drink three times.'
			],
		},
		{
			text: [
				'Drink for every letter in the username you\'re using for this game.'
			],
		},
		{
			text: [
				'Shortest player between {{user1}} and {{user2}} drinks two times.'
			],
		},
		{
			text: [
				'{{user1}} and {{user2}} must compare biceps.',
				'The alpha hands out three sips.'
			],
		},
		{
			text: [
				'Go around naming car brands starting with {{user1}}.',
				'The first person not able to think of a new one or repeat a used one must drink twice.'
			],
		},
		{
			text: [
				'The player with the weakest drink must take three sips.'
			],
		},
		{
			text: [
				'Go around revealing your guilty pleasure(s), starting with {{user1}}.',
				'The pussies who can\'t name any must drink four times.'
			],
		},
		{
			text: [
				'Athiests drink twice.',
				'Don\'t even think about having a discussion about religion right now.'
			],
		},
		{
			text: [
				'{{user1}} plays \'Truth or Dare\' with {{user2}} once.',
				'If {{user2}} refuses, they drink eight times.'
			],
		},
		{
			text: [
				'Anyone who plays or has played an instrument drink once.'
			],
		},
		{
			text: [
				'The player that {{user1}} has known the longest must drink twice.'
			],
		},
		{
			text: [
				'Do nothing and everyone drink once.',
				'Feels good to have all this power over you 😈.'
			],
		},
		{
			text: [
				'The most pale player must drink twice.',
				'Go get some vitamine D 🍆.'
			],
		},
		{
			text: [
				'Console peasants must drink twice.',
				'Just build a PC you normies.'
			],
		},
		{
			text: [
				'All immigrants drink twice.',
				'Welcome to Canada, eh! 🍁'
			],
		},
		{
			text: [
				'The drunkest player must take another drink.'
			],
		},
		{
			text: [
				'{{user1}} must snort their beverage and drink 7 times.'
			],
			virus: 8,
		},
		{
			text: [
				'Starting with {{user1}}, go around naming Asian countries.',
				'The first to repeat one or not be able to think of one must drink three times.'
			],
		},
		{
			text: [
				'The most formally dressed person must drink once.',
				'Put some sweats on you hardo.'
			],
		},
		{
			text: [
				'All players must try to lick their elbow.',
				'The players that can do so hands out five sips.'
			],
		},
		{
			text: [
				'{{user1}} busts a move, those that cannot reproduce it must drink three times.',
			],
		},
		{
			text: [
				'Everyone stand on one leg.',
				'The lazybones and the first to fall must drink three times.'
			],
		},
		{
			text: [
				'{{user1}}, hum a song (3-4 leçons).',
				'The first player to guess the song can choose someone to drink four times.'
			],
		},
		{
			text: [
				'{{user1}}, don\'t drink until your next turn.',
				'If you do by accident, finish your entire drink.'
			],
		},
		{
			text: [
				'{{user1}} touch your toes without bending your legs.',
				'Or, take three sips.'
			],
		},
		{
			text: [
				'{{user1}} has to mime something of their choice.',
				'The first person to guess hands out 4 sips, but if nobody can, Charlie Chaplin drinks three times.'
			],
		},
		{
			text: [
				'The person who hasn\'t drank in the longest time must drink five times.'
			],
		},
		{
			text: [
				'Drink three times if you\'ve been single for the last 6 months or more.',
				'(Sadbois stay sad).'
			],
		},
		{
			text: [
				'Everyone can insult {{user1}} but they must drink twice for every insult.',
				'We\'ll drink twice to that you fucking loser. -Dev team'
			],
		},
		{
			text: [
				'Everyone drink twice.'
			],
		},
		{
			text: [
				'{{user1}} has been bit by a venomous snake!',
				'They must drink 10 times for the antidote.'
			],
			virus: 10,
		},
		{
			text: [
				'{{user1}}, tell everyone two truths and one lie.',
				'Every player must guess which one is a lie and everyone that gets it wrong must drink three times.'
			],
		},
		{
			text: [
				'{{user1}} must tell {{user2}} that they love them, passionately.',
				'The lovebirds must each drink twice.'
			],
		},
		{
			text: [
				'Players with tattoos drink for every tattoo they have.',
				'We get it, you have a tattoo, nobody asked.'
			],
		},
		{
			text: [
				'Everyone ignore {{user1}} for one round.',
				'Oh... and drink four times loser.'
			],
		},
		{
			text: [
				'The host of this game must drink twice.'
			],
		},
		{
			text: [
				'Look at yourself in the camera.',
				'Drink twice you ugly fuck.'
			],
		},
		{
			text: [
				'For this round, {{user1}} has to drink three times with their left hand.'
			],
		},
		{
			text: [
				'Everyone must touch their head with both hands.',
				'The last person to do so must drink twice.'
			],
		},
		{
			text: [
				'Drink for every sibling you have.'
			],
		},
		{
			text: [
				'Everyone stand up.',
				'The last person standing must drink twice.'
			],
		},
		{
			text: [
				'{{user1}} and {{user2}} are in an arranged marriage.',
				'They both have to drink together for the next three rounds, and to kick things off drink three times.'
			],
		},
		{
			text: [
				'We think {{user1}} is feeling a little parched.',
				'11 sips for the thirsty man.'
			],
			virus: 11,
		},
		{
			text: [
				'Whoever left the game last but came back drinks 5 times.'
			],
		},
		{
			text: [
				'Players that have their birthdays on odd numbers drink three times.'
			],
		},
		{
			text: [
				'Everyone wearing glasses must drink two times.'
			],
		},
		{
			text: [
				'{{user1}} and {{user2}} are the chosen ones!',
				'Drink four times each, dweebs.'
			],
		},
		{
			text: [
				'Anyone drinking vodka must take a drink from their beverage.'
			],
		},
		{
			text: [
				'{{user1}}, try walking in a straight line.',
				'If you fail, then you must drink three times.'
			],
		},
		{
			text: [
				'WATERFALL! Keep drinking until {{user1}} stops drinking their beverage.'
			],
		},
		{
			text: [
				'{{user1}}, drink something you haven\'t drank today.',
				'If you have nothing different, drink 5 times, Boring Boris.'
			],
		},
		{
			text: [
				'{{user1}}, pick 3 adjectives to describe {{user2}}.',
				'Both of you drink two times.'
			],
		},
		{
			text: [
				'{{user1}} cannot swear for 3 rounds.',
				'If you swear then you must drink four times for each cuss.'
			],
		},
		{
			text: [
				'Anyone who claims they are not drunk enough, here\'s your chance to catch up!',
				'Those losers drink five times.'
			],
		},
		{
			text: [
				'{{user1}}, name every player\'s full names, if you can\'t do it then you must drink four times.'
			],
		},
		{
			text: [
				'If a player is drinking a white claw, they drink five times.',
				'Drink something good you hype sheeps.'
			],
		},
		{
			text: [
				'{{user1}}, choose which player is best dressed.',
				'They must drink twice.'
			],
		},
		{
			text: [
				'Anyone that currently has a job must drink two times.'
			],
		},
		{
			text: [
				'{{user1}} can create a rule that players must follow for three rounds.',
				'Anyone who breaks it must drink nine times.'
			],
		},
		{
			text: [
				'If you own any amount of Bitcoin, drink three times.',
				'Everyone else, what are you doing? Drink 6 times losers.'
			],
		},
		{
			text: [
				'Well, well, well... {{user1}}, today is your unlucky day.',
				'Drink 10 times to drown your bad luck away.'
			],
			virus: 10,
		},
		{
			text: [
				'Anyone born in the winter, drink four times.'
			],
		},
		{
			text: [
				'All cat lovers must drink two times for those satanic assholes.'
			],
		},
		{
			text: [
				'{{user1}}, spin around five times and take four sips.'
			],
		},
		{
			text: [
				'{{user1}}, tell everyone your favourite book and then drink two times.'
			],
		},
		{
			text: [
				'If your username for this current game is your actual name, drink three times.',
				'If it\'s not, drink five times. You\'re confusing everyone.'
			],
		},
		{
			text: [
				'{{user1}} must make an animal noise and then drink once.'
			],
		},
		{
			text: [
				'{{user1}} must not speak English or French for two rounds.',
				'If they do, drink four times.'
			],
		},
		{
			text: [
				'The male with the longest hair must drink three times.',
				'The female with the shortest hair must also drink three times.'
			],
		},
		{
			text: [
				'Anyone with a one syllable name must drink two times.',
			],
		},
		{
			text: [
				'The player with the least phone contacts must drink three times.',
				'You\'re probably the type who puts a snap story asking people for their numbers when you get a new phone, smh.'
			],
		},
		{
			text: [
				'If this is a sausage fest, boys drink up 4 times.',
				'Ladies, if there are any, y\'all chill.'
			],
		},
		{
			text: [
				'{{user1}}, tell everyone your favourite hobby.',
				'Every player with the same hobby must drink three times.'
			],
		},
		{
			text: [
				'{{user1}} has to drink double for next round.',
				'You better hope you don\'t get a bad card.'
			],
		},
		{
			text: [
				'{{user1}}, choose which two players look the most alike.',
				'They must both drink three times.'
			],
		},
		{
			text: [
				'{{user1}}, you thought you were safe? Think again.',
				'Drink 12 times, no excuses.'
			],
			virus: 12
		},
		{
			text: [
				'Looks like {{user1}} finally got chosen for something!',
				'Now drink 10 times buddy.'
			],
			virus: 10,
		},
		{
			text: [
				'{{user1}} must say the date of birth of {{user2}}.',
				'If correct, {{user2}} drinks twice, otherwise {{user1}} drinks twice.'
			],
		},
		{
			text: [
				'{{user1}} tilt your head all the way back and take drink once.',
				'Oh and please... try not to spill it.'
			],
		},
		{
			text: [
				'Repeat the last card played.',
				'If it was a bad one, sorry not sorry 🤷‍♂️.'
			],
		},
		{
			text: [
				'{{user1}}, blow a kiss to {{user2}} or drink four times.'
			],
		},
		{
			text: [
				'{{user1}}, choose whether {{user2}} drinks four times or not.',
				'If you chose yes, {{user1}} also drinks four times.'
			],
		},
		{
			text: [
				'Drink three times if you have a piercing.'
			],
		},
		{
			text: [
				'{{user1}} must only whisper until their next turn.',
				'If they fail to do so, they drink four times.'
			],
		},
		{
			text: [
				'The player who\'s going to bald first drinks three times.',
				'If no one volunteers as tribute, don\'t be scared to call them out 😉.'
			],
		},
		{
			text: [
				'{{user1}}, do a handstand or drink three times.'
			],
		},
		{
			text: [
				'Whoever has travelled to the most countries can give out four sips.'
			],
		},
		{
			text: [
				'Any players wearing any makeup must drink two times.'
			],
		},
		{
			text: [
				'{{user1}} guesses a number from one to five.',
				'{{user2}} guesses which number it is. If they get it right, everyone drinks two times.'
			],
		},
		{
			text: [
				'For three rounds, if anyone answers a question asked by {{user1}}, they must drink three times.'
			],
		},
		{
			text: [
				'WELCOME TO YOUR DOOM 👹',
				'{{user1}} has to take 20 sips, basically down that shit.'
			],
			virus: 20,
		},
		{
			text: [
				'Drink two times if you played more than one sport.'
			],
		},
		{
			text: [
				'Take a drink for every tab you have open on your phone\'s Internet browser.',
				'We\'ll go easy on you, only drink a maximum of 15 times.'
			],
		},
		{
			text: [
				'Check how many apps you have open on your phone.',
				'One app = one sip, finish your drink if you\'re too lazy to count.'
			],
		},
		{
			text: [
				'Drink once if you\'ve ever used a fake ID.'
			],
		},
		{
			text: [
				'Take four sips if you\'re wearing a baseball cap or beanie right now.',
				'Take an extra three if it\'s from Epöch 🕒.'
			],
		},
		{
			text: [
				'The host of the game must drink four times.'
			],
		},
		{
			text: [
				'Everyone except the person with the least Instagram followers must drink three times.',
				'IG game weak, gotta chase that clout fam. At least you didn\'t drink, loser.'
			],
		},
		{
			text: [
				'The person with the most Instagram followers gives out four sips.',
				'We get it you\'re an influencer, big whoop, get a real job.'
			],
		},
		{
			text: [
				'Drink four times if you have one of those \'unfollowers\' apps.',
				'You know, the ones that notify you when someone unfollows you'
			],
		},
		{
			text: [
				'Free Pass: {{user1}} can keep or donate this (virtual) card.',
				'It can be used once to get out of drinking... choose wisely.'
			],
		},
		{
			text: [
				'{{user1}}, tell everyone about the last time you laughed inappropriately and drink three times.',
				'If any other player in this group was involved with the situation, they also drink three times.'
			],
		},
		{
			text: [
				'{{user1}}, call out the person you think can chug the fastest.',
				'Big shot needs to take five sips, quick.'
			],
		},
		{
			text: [
				'{{user1}}, call out the person who has the most monstrous feet.',
				'Big foot drinks two times.'
			],
		},
		{
			text: [
				'{{user1}}, name all those who have or would like to have dogs as pets.',
				'Drink two times if you\'re wrong.'
			],
		},
		{
			text: [
				'BIG DICK CONTEST, {{user1}} and {{user2}} must battle it out in a drink off.',
				'First person to down a fresh drink wins and the other must take an additional 15 drinks on top of that.'
			],
		},
		{
			text: [
				'{{user1}}, call out whoever has owned a fish as a pet.',
				'Take 3 sips if you\'re wrong or don\'t know.'
			],
		},
		{
			text: [
				'Everyone call out the Google Fanboy.',
				'Whoever gets called out the most drinks three times.'
			],
		},
		{
			text: [
				'Everyone call out the Apple Fanboy.',
				'Whoever gets called out the most drinks three times.'
			],
		},
		{
			text: [
				'Whoever still uses Facebook drinks five times.',
				'Keep up with the jones\' boomer.'
			],
		},
		{
			text: [
				'{{user1}}, if you upgraded your phone in the past 6 months, drink three times.'
			],
		},
		{
			text: [
				'If {{user1}} has not updated their phone in the past two years, drink four times.',
				'Update that shit...'
			],
		},
		{
			text: [
				'{{user1}} needs to take a (virtual) selfie for the memories, with the others in the shot if possible.',
				'The picture is also to remember that they then drank 15 times. So yeah, drink 15 times, buddy.'
			],
			virus: 15
		},
		{
			text: [
				'If any player has ever streaked in public before, drink four times.'
			],
		},
		{
			text: [
				'If {{user1}} has ever been \'naked miled\' by another team in beer pong, drink five times.',
				'However, if they\'ve \'naked miled\' another team, only drink once.'
			],
		},
		{
			text: [
				'If a player\'s name starts with the letter A, drink three times.'
			],
		},
		{
			text: [
				'If a player can touch their nose with their tongue, they can hand out 6 sips.'
			],
		},
		{
			text: [
				'{{user1}} must take two sips if the hour of the day is an odd number.'
			],
		},
		{
			text: [
				'{{user1}} must name the middle name of {{user2}}.',
				'If they guess it wrong or they do not have one, drink two times.'
			],
		},
		{
			text: [
				'If {{user1}} is younger than {{user2}}, {{user1}} drinks four times.',
				'If not, {{user2}} drinks four times.'
			],
		},
		{
			text: [
				'{{user1}} must juggle three items they find in their room for 5 seconds.',
				'If they fail or do not have anything to juggle, well it\'s too bad, drink 5 times.'
			],
		},
		{
			text: [
				'{{user1}} wakes up to flock of rabbid raccoons, and the only way to defend themselves is to drink the super duper potion.',
				'Unfortunately the super duper potion consists of 10 sips, get rolled and smoked by some racky tackies, kiddo.'
			],
			virus: 10,
		},
		{
			text: [
				'Everyone vote for which character they prefer, Naruto or Sasuke.',
				'The side with the least amount of people drinks three times. If it\'s a draw, everyone drinks once. If you haven\'t watched Naruto, do yourself a favor and watch it.'
			],
		},
		{
			text: [
				'Everyone vote for which character they prefer, Kakashi or Shikamaru.',
				'The side with the least amount of people drinks twice. If it\'s a draw, everyone drinks once. If you haven\'t watched Naruto, drink five time.'
			],
		},
		{
			text: [
				'Everyone vote for which character they prefer, Minato or Jiraiya.',
				'The side with the least amount of people drinks three times. If it\'s a draw, everyone drinks once. No spoilers if someone is currently watching the show.'
			],
		}
	]
};

export const cardPackLength = cardPack.cards.length;

import React, { useState, useContext } from 'react';
import './usernamePrompt.css';
import { AnimatePresence, motion } from 'framer-motion';
import { AuthContext, PopupContext } from '../backend/authProvider';
import { checkIfUsernameIsTaken, updateUsername } from '../backend/database';

function UsernameModal(props) {
	const [username, setUsername] = useState('');
	const [checkbox, setCheckbox] = useState(true);
	const { setShowPopup, setText } = useContext(PopupContext);
	const { currentUser } = useContext(AuthContext);

	const handleUsernameInputChange = (e) => {
		setUsername(e.target.value);
	};

	const handleCheckboxChange = (e) => {
		setCheckbox(e.target.checked);
	};

	const updateUsernameInDB = () => {
		if (username.length < 1) {
			setText('We literally just asked you for a username, so please enter one 🥺👉👈');
			setShowPopup(true);
			return;
		}

		checkIfUsernameIsTaken(username).then(taken => {
			if (taken) {
				setText('Sorry, ' + username + ' is already taken! Try being creative for once, and try again.');
				setShowPopup(true);
			} else {
				updateUsername(currentUser.uid, username, checkbox).then(() => {
					props.setShowPrompt({
						show:false,
						backButton: false,
					});
					setText('Cool! Username and leaderboards settings have been set.');
					setShowPopup(true);
				}).catch(error => {
					setText('Oups! Something went wrong and we have no idea why 😕');
					setShowPopup(true);
					console.error('Error updating username: ', error);
				});
			}
		});
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 1 }}
			style={{
				position: 'fixed',
				top: '0%',
				left: '50%',
				transform: 'translate3d(-50%,0,0)'
			}}
			className="modal-grid"
		>
			<motion.div className="username-prompt-header">
				{props.showPrompt.backButton ? <motion.button onClick={() => {
					props.setShowPrompt({
						show: false,
						backButton: false,
					});
				}} className="username-prompt-back">
					<ion-icon name="arrow-back-outline"></ion-icon>
				</motion.button> : null}
				<div className="username-prompt-header-title">Drinks<br />Away</div>
			</motion.div>
			{ !props.showPrompt.backButton ? 
				<motion.div className="leaderboards-prompt-header">
				It doesn&lsquo;t look like we have a username for you!<br />Let&lsquo;s change that...
				</motion.div>
				: <motion.div className="leaderboards-prompt-header">
                Let&lsquo;s change that your username and leaderboard settings!
				</motion.div>
			}
			<motion.div className="add-username">
				<motion.input whileTap={{ scale: 1.1 }} className="username-input-onboarding" placeholder="Add a leaderboard username:" onChange={handleUsernameInputChange} />
				<p className="leaderboards-prompt-checkbox">Also, leave this box checked if you want to be a part of the &quot;Hall of Drinkers&quot; leaderboards! 🍻</p>
				<input defaultChecked={true} className="checkbox-leaderboards" type="checkbox" onChange={handleCheckboxChange} />
			</motion.div>
			<motion.button onClick={updateUsernameInDB} className="on-boarding-button">
                Save settings & continue!
			</motion.button>
		</motion.div>
	);
}

const UsernamePrompt = ({ showPrompt, setShowPrompt }) => {
	return (
		<AnimatePresence>
			{showPrompt.show && (
				<UsernameModal showPrompt={showPrompt} setShowPrompt={setShowPrompt} />
			)}
		</AnimatePresence>
	);
};

export default UsernamePrompt;

import React, {useContext} from 'react';
import {signOut} from '../backend/authentication';
import {motion} from 'framer-motion';
import './settings.css';
import {AuthContext} from '../backend/authProvider';

const Settings = (props) => {
	document.querySelector('meta[name="theme-color"]').setAttribute('content', '#457b9d');

	const {setShowOnboarding} = useContext(AuthContext);

	return (
		<motion.div className="app-container-settings">
			<motion.div className="settings-grid">
				<motion.div
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					className="settings-header">
					<motion.button onClick={() => props.history.push('/')} className="settings-to-home">
						<ion-icon name="arrow-back-outline"></ion-icon>
					</motion.button>

					<motion.div className="settings-title">
						SETTINGS
					</motion.div>
				</motion.div>
				<motion.div
					initial={{y: 100, opacity: 0}}
					animate={{y: 0, opacity: 1}}
					transition={{delay: 0.4}}
					className="settings-rows">
					<p>Click here to sign out.</p>
					<motion.button
						initial={{y: 100, opacity: 0}}
						animate={{y: 0, opacity: 1}}
						className="settings-button" onClick={signOut}>
						SIGN OUT
					</motion.button>
				</motion.div>
				<motion.div
					initial={{y: 100, opacity: 0}}
					animate={{y: 0, opacity: 1}}
					transition={{delay: 0.6}}
					className="settings-rows">
					<p>Click here to change your username or leaderboards settings.</p>
					<motion.button
						initial={{y: 100, opacity: 0}}
						animate={{y: 0, opacity: 1}}
						onClick={() => {setShowOnboarding({
							show: true, 
							backButton: true
						});}} className="settings-button">
						CHANGE
					</motion.button>
				</motion.div>
				<motion.div
					initial={{y: 100, opacity: 0}}
					animate={{y: 0, opacity: 1}}
					transition={{delay: 0.8}}
					className="social">
					<div className="social-title">CREATORS OF DRINKS AWAY</div>
					<div className="social-list">
						<p className="social-items">Alex Stathis
							<a href="https://www.instagram.com/al3xstathis/" target="_blank" rel="noopener noreferrer">
								<ion-icon name="logo-instagram"></ion-icon>
							</a>
						</p>
						<p className="social-items">Nicolas MacBeth
							<a href="https://www.instagram.com/nicolas.macbeth/" target="_blank" rel="noopener noreferrer">
								<ion-icon name="logo-instagram"></ion-icon>
							</a>
						</p>
						<p className="social-items">Anthony Porco
							<a href="https://www.instagram.com/anthony.porco/" target="_blank" rel="noopener noreferrer">
								<ion-icon name="logo-instagram"></ion-icon>
							</a>
						</p>
					</div>
				</motion.div>
			</motion.div>
		</motion.div>
	);
};

export default Settings;

import React, { useState, useEffect, useContext } from 'react';
import './game.css';
import Card from '../components/card';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { cardPack, specialCards } from '../assets/cards';
import firebase from '../backend/firebase';
import 'firebase/database';
import { AuthContext, PopupContext } from '../backend/authProvider';
import SeatingArrangement from '../components/seatingArrangement';

const Game = (props) => {
	document.querySelector('meta[name="theme-color"]').setAttribute('content', '#FAF9F9');

	const { gameID } = useParams();
	const { setShowPopup, setText, setTime } = useContext(PopupContext);
	const { currentUser, gameName } = useContext(AuthContext);

	const [gameInfo, setGameInfo] = useState({
		isHost: null,
		cards: null
	});

	const [cardInfo, setCardInfo] = useState({
		players: {},
		currentCard: -1,
		currentDrinkers: [],
	});

	const [currentCardArray, setCurrentCardArray] = useState([]);
	const [currentPlayers, setCurrentPlayers] = useState([]);
	const [virusCard, setVirusCard] = useState(false);
	const [specialCard, setSpecialCard] = useState(false);
	const [showSeats, setShowSeats] = useState(false);
	const [ready, setReady] = useState(false);
	const [disableActionButtons, setDisableActionButtons] = useState(false);
	const [exitWarning, setExitWarning] = useState(false);
	const [lastCard, setLastCard] = useState({
		cardNumber: -1,
		time: Date.now(),
	});

	const redirectAndNotify = (path, text) => {
		props.history.push(path);
		setText(text);
		setShowPopup(true);
	};

	const shuffle = (arr) => {
		let m = arr.length, t, i;
		while (m) {
			i = Math.floor(Math.random() * m--);

			t = arr[m];
			arr[m] = arr[i];
			arr[i] = t;
		}
		return arr;
	};

	const incrementCard = (newPlayers = currentPlayers, ready = false) => {
		if (disableActionButtons){
			redirectAndNotify('/', gameInfo.isHost ? 'Game is done! Please create a new room to play again! 🍻' : 'Game is done! Please ask your host to create a new room to play again! 🍻');
			return;
		}

		if (!ready && lastCard.time + 5000 > Date.now()){
			setText('Please wait a minimum of 5 seconds before going to the next card.');
			setShowPopup(true);
			return;
		}

		const newDrinkers = shuffle(newPlayers.map(player => player[0]));
		const nextCard = (cardInfo.currentCard <= newPlayers.length * 20 ) ? cardInfo.currentCard + 1 : gameInfo.cards.length - 1;
		const unreadyPlayers = {...cardInfo.players};

		for (let key in unreadyPlayers) {
			// eslint-disable-next-line no-prototype-builtins
			if (unreadyPlayers.hasOwnProperty(key)) {
				unreadyPlayers[key].ready = false;
			}
		}

		const updated = {
			'/currentDrinkers': newDrinkers,
			'/currentCard': nextCard,
			'/users': unreadyPlayers,
			'/lastUpdate': Date.now()
		};

		setCardInfo({
			currentDrinkers: newDrinkers,
			players: unreadyPlayers,
			currentCard: nextCard
		});

		firebase.database().ref('Rooms/' + gameID).update(updated);
	};

	const exitOrQuitGame = () => {
		if (gameInfo && gameInfo.isHost) {
			if (!exitWarning){
				setText('As the host, quitting the game will end it for all players. Are you sure you want to exit? Click again to end the game.');
				setShowPopup(true);

				setExitWarning(true);
				setTimeout(() => {
					setExitWarning(false);
				}, 5000);
				return;
			}

			firebase.database().ref('Rooms/' + gameID).update({
				'/gameDone': true,
				'/lastUpdate': Date.now(),
			});
			redirectAndNotify('/', 'You have ended the game for all players in your room.\nThanks for playing and come again! 🍺');
		} else {
			if (!exitWarning){
				setText('Are you sure you want to leave the game? Click again to leave.');
				setShowPopup(true);
				
				setExitWarning(true);
				setTimeout(() => {
					setExitWarning(false);
				}, 5000);
				return;
			}

			firebase.database().ref('Rooms/' + gameID + '/users/' + currentUser.uid).update({
				'/present': false,
				'/lastUpdate': Date.now(),
			});
			redirectAndNotify('/', 'You\'ve left the game, thanks for playing and come again! 🍺');
		}
	};

	const kickPlayer = (i) => {
		firebase.database().ref('Rooms/' + gameID + '/users/' + currentPlayers[i][0]).update({
			'kicked': true,
			'present': false,
			'lastUpdate': Date.now(),
		});
		setText('Player kicked. 👋');
		setShowPopup(true);
	};

	const readyUp = () => {
		if (ready || cardInfo.currentCard === -3){
			return;
		}

		if (disableActionButtons){
			redirectAndNotify('/', gameInfo.isHost ? 'Game is done! Please create a new room to play again! 🍻' : 'Game is done! Please ask your host to create a new room to play again! 🍻');
			return;
		}

		setReady(true);
		firebase.database().ref('Rooms/' + gameID + '/users/' + currentUser.uid).update({
			'/ready': true,
			'/lastUpdate': Date.now(),
		});
	};

	const formatCardArr = () => {
		let card = [];

		if (gameInfo.cards[cardInfo.currentCard] < 0){ // Special card (end)
			card = specialCards.cards[gameInfo.cards[cardInfo.currentCard] * -1]['text'];
			setDisableActionButtons(true);
			setSpecialCard(true);
			setVirusCard(false);
		} else if (cardInfo.currentCard < 0) { // Special cards (beginning)
			card = specialCards.cards[(cardInfo.currentCard * -1) - 1]['text'];
			setSpecialCard(true);
			setVirusCard(false);
		} else { // Normal and virus cards
			card = cardPack.cards[gameInfo.cards[cardInfo.currentCard]]['text'];
			for (let i = 0; i < card.length; i++) {
				for (let j = 0; j < cardInfo.currentDrinkers.length; j++) {
					card[i] = card[i].split('{{user' + (j + 1) + '}}').join(cardInfo.players[cardInfo.currentDrinkers[j]].username);
				}
			}

			setSpecialCard(false);
			let virus = cardPack.cards[gameInfo.cards[cardInfo.currentCard]]['virus'];
			setVirusCard(virus);

			if (gameInfo.isHost && !!virus){
				addDrinksToRoom(cardInfo.currentDrinkers[0], virus);
			}
		}

		setCurrentCardArray(card);
	};

	const addDrinksToRoom = (drinker, virus) => {
		firebase.database().ref('Rooms/' + gameID + '/users/' + drinker).transaction((data) => {
			if (data){
				if (data.currentDrinksIndices && !data.currentDrinksIndices.includes(cardInfo.currentCard)){
					data.currentDrinksIndices.push(cardInfo.currentCard);
					data.currentDrinks += virus;
					return data;
				} else if (!data.currentDrinksIndices) {
					data.currentDrinksIndices = [cardInfo.currentCard];
					data.currentDrinks += virus;
					return data;
				}
			}
		}, (error, committed) => {
			if (!error && committed){
				addDrinksToLeaderboard(drinker, virus);
			}
		});
	};

	const addDrinksToLeaderboard = (user, virus) => {
		firebase.functions().httpsCallable('addDrinks')({
			user,
			virus
		}).catch(error => {
			console.error('Firebase cloud function error (addDrinks): ', error);
		});
	};

	const updateUsersArray = () => {
		let newPlayers = [];
		let allReady = true;

		for (let key in cardInfo.players) {
			// eslint-disable-next-line no-prototype-builtins
			if (cardInfo.players.hasOwnProperty(key)) {
				newPlayers.push([key, cardInfo.players[key]]);
				if (!cardInfo.players[key].ready && cardInfo.players[key].present){
					allReady = false;
				}
			}
		}

		newPlayers = newPlayers.filter(player => player[1].present);
		newPlayers.sort((a, b) => a[1].position - b[1].position);

		setCurrentPlayers(newPlayers);

		if (gameInfo.isHost && allReady) {
			incrementCard(newPlayers, true);
		}
	};

	// Watch for new cards and set their start time
	useEffect(() => {
		if (cardInfo.currentCard !== lastCard.cardNumber){
			setLastCard({
				cardNumber: cardInfo.currentCard,
				time: Date.now(),
			});
		}
	}, [cardInfo.currentCard]);

	// Format card array when card data is updated and update users array
	useEffect(() => {
		if (gameInfo.isHost === null || gameInfo.cards === null) {
			return;
		}

		formatCardArr();
		updateUsersArray();
	}, [cardInfo]);

	// Handle first loading the game page
	useEffect(() => {
		if (gameInfo.isHost === null) {
			// Check if room exists
			firebase.database().ref('Rooms/' + gameID).once('value', snapshot => {
				if (!snapshot || !snapshot.val() || snapshot.val().gameDone) {
					setTime(5000);
					redirectAndNotify('/join', 'Oups, that didn\'t work.\nThis room code is not valid, was the game ended by the host or because of inactivity?');
					return;
				}

				const data = snapshot.val();
				setCardInfo({
					players: data.users,
					currentCard: data.currentCard,
					currentDrinkers: data.currentDrinkers
				});

				if (!data.users[currentUser.uid] && gameName === null){
					redirectAndNotify('/join', 'We don\'t have a username for you for this game.\nPlease join using this menu.');
					return;
				}

				// Add user if they were not already, or kicked
				if (!data.users[currentUser.uid] || data.users[currentUser.uid].kicked || !data.users[currentUser.uid].present) {
					firebase.database().ref('Rooms/' + gameID).transaction(game => {
						if (game) {
							game.totalUsers++;
							game.lastUpdate = Date.now();

							if (game.users[currentUser.uid]) {
								game.users[currentUser.uid].present = true;
								game.users[currentUser.uid].kicked = false;
							} else {
								game.users[currentUser.uid] = {
									currentDrinks: 0,
									username: gameName,
									present: true,
									kicked: false,
									position: (game.totalUsers - 1),
								};
							}
						}
						return game;
					});
				}

				setGameInfo({
					isHost: data.host === currentUser.uid,
					cards: data.cards.split(','),
				});
			});
		}
	}, []);

	// Handle updates from realtime db
	useEffect(() => {
		if (gameInfo.isHost === null || gameInfo.cards === null) {
			return;
		}

		const reference = firebase.database().ref('Rooms/' + gameID);
		reference.on('value', snapshot => {
			if (!snapshot || !snapshot.val()) {
				return;
			}

			const data = snapshot.val();

			// Set ready
			setReady(!!data.users[currentUser.uid].ready);

			// Update users
			setCardInfo({
				players: data.users,
				currentCard: data.currentCard,
				currentDrinkers: data.currentDrinkers
			});

			// Handle game done
			if (data.gameDone) {
				redirectAndNotify('/', 'Game ended by host! Thanks for playing, and come again soon! 🍻'); // TODO: go to leaderboard
				return;
			}

			// Not enough players
			if (data.currentDrinkers && data.currentDrinkers.length < 2) {
				redirectAndNotify('/', 'Not enough players to continue the game. 🙄\nYou need minimum two players.');
				return;
			}

			// Kicked from game
			if (data.users[currentUser.uid].kicked === true) {
				redirectAndNotify('/', 'Who did you piss off? 😡\nYou got kicked from the game!');
				return;
			}
		});

		return () => reference.off();
	}, [gameInfo.isHost, gameInfo.cards]);

	return (
		<div className="app-container-game">
			<motion.div className="game-grid">
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					className='game-header'>
					<motion.button
						className="exit-game"
						whileTap={{ scale: 0.9 }}
						onClick={exitOrQuitGame}
					>
						<ion-icon class="icon-size" id="ion-icon" name="close-outline">
						</ion-icon>
					</motion.button>
					<motion.div className="game-id">
						Code: {gameID}
					</motion.div>
				</motion.div>
				<motion.div className="game-card">
					<AnimatePresence>
						<Card virus={virusCard} special={specialCard} gameInfo={gameInfo} moveToNextCard={() => incrementCard()} currentCardArray={currentCardArray} />
					</AnimatePresence>
				</motion.div>

				{gameInfo.isHost ?
					<motion.div className="toolbar">
						<motion.div
							initial={{ x: 100, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							exit={{ x: -100, opacity: 0 }}
							transition={{
								delay: 0.5
							}}
							className="game-toolbar">
							<motion.button
								onClick={() => { setShowSeats(true); }}
								initial={{ x: 100, opacity: 0 }}
								animate={{ x: 0, opacity: 1 }}
								exit={{ x: -100, opacity: 0 }}
								transition={{
									delay: 0.8
								}}
								whileTap={{ scale: 0.9 }} className="toolbar-button">
								<ion-icon className="toolbar-icons" name="people">
								</ion-icon>
							</motion.button>
							<motion.button
								onClick={() => incrementCard()}
								initial={{ x: 100, opacity: 0 }}
								animate={{ x: 0, opacity: 1 }}
								exit={{ x: -100, opacity: 0 }}
								transition={{
									delay: 1
								}}
								whileTap={{ scale: 0.9 }} className="toolbar-button">
								<ion-icon className="toolbar-icons" name="arrow-forward-outline">
								</ion-icon>
							</motion.button>
							<motion.button
								initial={{ x: 100, opacity: 0 }}
								animate={{ x: 0, opacity: 1 }}
								exit={{ x: -100, opacity: 0 }}
								transition={{
									delay: 1.2
								}}
								whileTap={{ scale: 0.9 }} className={ready ? 'toolbar-button-ready' : 'toolbar-button'}
								onClick={readyUp}>
								<ion-icon className="toolbar-icons" name="thumbs-up">
								</ion-icon>
							</motion.button>
						</motion.div>
					</motion.div>
					:
					<motion.div className="toolbar">
						<motion.div
							initial={{ x: 100, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							exit={{ x: -100, opacity: 0 }}
							transition={{
								delay: 0.5
							}}
							className="game-toolbar">
							<motion.button
								onClick={() => { setShowSeats(true); }}
								initial={{ x: 100, opacity: 0 }}
								animate={{ x: 0, opacity: 1 }}
								exit={{ x: -100, opacity: 0 }}
								transition={{
									delay: 0.8
								}}
								whileTap={{ scale: 0.9 }} className="toolbar-button">
								<ion-icon className="toolbar-icons" name="people">
								</ion-icon>
							</motion.button>
							<motion.button
								initial={{ x: 100, opacity: 0 }}
								animate={{ x: 0, opacity: 1 }}
								exit={{ x: -100, opacity: 0 }}
								transition={{
									delay: 1
								}}
								whileTap={{ scale: 0.9 }} className={ready ? 'toolbar-button-ready' : 'toolbar-button'}
								onClick={readyUp}>
								<ion-icon className={'toolbar-icons'} name="thumbs-up">
								</ion-icon>
							</motion.button>
						</motion.div>
					</motion.div>
				}
			</motion.div >
			<SeatingArrangement kick={kickPlayer} userUID={currentUser.uid} players={currentPlayers} isHost={gameInfo && gameInfo.isHost} visible={showSeats} setVisible={setShowSeats} />
		</div>
	);
};
export default Game;

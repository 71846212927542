import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../backend/authProvider';

const RouteWrapper = ({ component: RouteComponent, privateRoute = true, ...rest }) => {
	const { currentUser } = useContext(AuthContext);

	if (privateRoute) {
		return (
			<Route
				{...rest}
				render={routeProps =>
					currentUser ? (
						<RouteComponent {...routeProps} currentUser={currentUser} />
					) : (
						<Redirect to={'/registration'} />
					)
				}
			>
			</Route>
		);
	} else {
		return (
			<Route
				{...rest}
				render={routeProps => <RouteComponent {...routeProps} currentUser={currentUser} />}
			>
			</Route>
		);
	}
};

export default RouteWrapper;

import React from 'react';
import Join from './pages/join';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/home';
import Host from './pages/host';
import Registration from './pages/registration';
import Game from './pages/game';
import { AuthProvider } from './backend/authProvider';
import Settings from './pages/settings';
import SignInSuccess from './pages/signInSuccess';
import RouteWrapper from './components/routeWrapper';
import Popup from './components/popup';
import Leaderboard from './pages/leaderboard';
import Info from './pages/info';

function App() {
	return (
		<AuthProvider>
			<Router>
				<Switch>
					<RouteWrapper path="/registration" privateRoute={false} component={Registration} />
					<RouteWrapper path="/sign-in-success" component={SignInSuccess} />
					<RouteWrapper path="/join" component={Join} />
					<RouteWrapper path="/host" component={Host} />
					<RouteWrapper path="/game/:gameID" component={Game} />
					<RouteWrapper path="/settings" component={Settings} />
					<RouteWrapper path="/leaderboard" component={Leaderboard} />
					<RouteWrapper path="/info" component={Info} />
					<Route component={Home} />
				</Switch>
			</Router>
			<Popup />
		</AuthProvider>
	);
}

export default App;

import React, {useEffect, useState} from 'react';
import './card.css';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';


const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
	return Math.abs(offset) * velocity;
};

const Card = (props) => {
	const controls1 = useAnimation();
	const controls2 = useAnimation();
	const offset = 500;

	const [card1, setCard1] = useState([]);
	const [card2, setCard2] = useState([]);
	const [currentCard, setCurrentCard] = useState(null);

	useEffect(async () => {
		if (props.currentCardArray.length === 0){
			return;
		}

		if (currentCard === 1){
			setCard2(props.currentCardArray);
			setCurrentCard(2);

			controls2.start({
				x: 0,
				opacity: 1
			});
			await controls1.start({
				x: -offset,
				opacity: 0,
			});

			controls1.start({
				x: offset
			});
		} 
		
		if (currentCard === 2){
			setCard1(props.currentCardArray);
			setCurrentCard(1);

			controls1.start({
				x: 0,
				opacity: 1
			});
			await controls2.start({
				x: -offset,
				opacity: 0,
			});

			controls2.start({
				x: offset
			});
		}

		if (currentCard === null){
			setCard1(props.currentCardArray);
			setCurrentCard(1);

			controls1.start({
				x: 0,
				opacity: 1,
			});
		}
	}, [props.currentCardArray]);

	return (
		<>
			<AnimatePresence>
				<motion.div
					animate={controls1}
					drag="x"
					initial={{ x: offset, opacity: 0 }}
					dragConstraints={{
						left: 0,
						right: 0
					}}
					dragElastic={0.8}
					onDragEnd={(e, { offset, velocity }) => {
						const swipe = swipePower(offset.x, velocity.x / 5);
						if (swipe < -swipeConfidenceThreshold && props.gameInfo && props.gameInfo.isHost) {
							props.moveToNextCard();
						}
					}}
					className={(props.virus || props.special) ? (props.virus ? 'card-virus' : 'card-special') : 'card'}>
					<motion.div className="card-info-1">
						{card1[0]}
					</motion.div>
					{card1.length === 2 ?
						<motion.div className="card-info-2">
							{card1[1]}
						</motion.div>
						: null}
				</motion.div>
			</AnimatePresence>
			<AnimatePresence>
				<motion.div
					animate={controls2}
					drag="x"
					initial={{ x: offset, opacity: 0 }}
					dragConstraints={{
						left: 0,
						right: 0
					}}
					dragElastic={0.8}
					onDragEnd={(e, { offset, velocity }) => {
						const swipe = swipePower(offset.x, velocity.x / 5);
						if (swipe < -swipeConfidenceThreshold && props.gameInfo && props.gameInfo.isHost) {
							props.moveToNextCard();
						}
					}}
					className={(props.virus || props.special) ? (props.virus ? 'card-virus' : 'card-special') : 'card'}>
					<motion.div className="card-info-1">
						{card2[0]}
					</motion.div>
					{card2.length === 2 ?
						<motion.div className="card-info-2">
							{card2[1]}
						</motion.div>
						: null}
				</motion.div>
			</AnimatePresence>
		</>
	);
};

export default Card;

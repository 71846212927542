import firebase from './firebase.js';
import { addNewUserToDB } from './database';

export function googleSignIn() {
	let provider = new firebase.auth.GoogleAuthProvider();
	firebase.auth().useDeviceLanguage();
	firebase.auth().signInWithRedirect(provider);
}

export function signOut() {
	firebase.auth().signOut()
		.catch(function (error) {
			console.error('Sign out failed: ', error);
		});
}

export function signInWithEmailAndPass(creds, setPopupShow, setText) {
	firebase.auth().signInWithEmailAndPassword(creds.email, creds.password)
		.catch((error) => {
			if (error.code === 'auth/invalid-email') {
				setText('Usually, emails don\'t look like that lol\nEnter a valid email!');
				setPopupShow(true);
			} else if (error.code === 'auth/user-not-found') {
				setText('Why would you sign in without an account?\nSign up, dummy!');
				setPopupShow(true);
			} else if (error.code === 'auth/wrong-password') {
				setText('Imagine forgetting your password.\nCouldn\'t be me but clearly is you!');
				setPopupShow(true);
			} else {
				console.error(error);
				setText('To be honest... we don\'t know what happened but it failed 🤷‍️');
				setPopupShow(true);
			}
		});
}

export function signUpWithEmailAndPass(creds, setPopupShow, setText) {
	firebase.auth().createUserWithEmailAndPassword(creds.email, creds.password)
		.then((user) => {
			if (user.additionalUserInfo && user.additionalUserInfo.isNewUser) {
				addNewUserToDB(user.user.uid, user.user.email);
			}
		})
		.catch((error) => {
			if (error.code === 'auth/invalid-email') {
				setText('There\'s something wrong with how you wrote that email...\nWe\'ll let you figure it out!');
				setPopupShow(true);
			} else if (error.code === 'auth/weak-password') {
				setText('My grandma could guess that password.\nFind something stronger!');
				setPopupShow(true);
			} else if (error.code === 'auth/email-already-in-use') {
				setText('Did you forget that you made an account?\nYou should probably sign in instead...');
				setPopupShow(true);
			} else {
				console.error(error);
				setText('Bro we have no idea what went wrong...\nTry again some other time!');
				setPopupShow(true);
			}
		});
}

export function resetPassword(email, setPopupShow, setText){
	firebase.auth().sendPasswordResetEmail(email, {
		url: 'https://drinks-away.ca/registration'
	}).then(() => {
		setText('An email has been sent to your inbox for a password reset.');
		setPopupShow(true);
	}).catch((error) => {
		if (error.code === 'auth/invalid-email') {
			setText('Please enter a valid email to reset your password.');
			setPopupShow(true);
		} else if (error.code === 'auth/user-not-found') {
			setText('That email doesn\'t exists in our records, please sign up!');
			setPopupShow(true);
		} else {
			setText('That didn\'t work, and we\'re not sure why. Sorry!');
			setPopupShow(true);
			console.error(error);
		}
	});
}

export function getRedirectResult(setPopupShow, setText) {
	firebase.auth().getRedirectResult().then((result) => {
		if (result.credential) {
			if (result.additionalUserInfo.isNewUser) {
				addNewUserToDB(result.user.uid, result.user.email);
			}
		}
	}).catch((error) => {
		if (error.errorCode === 'auth/account-exists-with-different-credential') {
			setText('You probably already signed up with email and password.\nDo that instead please!');
			setPopupShow(true);
		} else {
			console.error(error);
			setText('Google fucked up\nIt\'s not our fault\nBut the sign in failed 🤪');
			setPopupShow(true);
		}
	});
}

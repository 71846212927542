import React from 'react';
import './seatingArrangement.css';
import { AnimatePresence, motion } from 'framer-motion';

const SeatingArrangement = (props) => {
	const currentPlayers = props.players;

	return (
		props.visible ?
			<div className="app-container-seating">
				<AnimatePresence>
					<motion.div
						initial={{ opacity: 0, y: 100 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 100 }}
						className="seating-arrangement">
						<motion.div className="seating-arrangement-header">
                            CURRENT VIRTUAL SEATING
							<button
								onClick={() => props.setVisible(false)}
								className="back-to-game">
								<ion-icon class="exit-icon" className="exit-icon" id="ion-icon" name="close-outline">
								</ion-icon>
							</button>
						</motion.div>
						<SeatingList userUID={props.userUID} kick={props.kick} isHost={props.isHost} playerList={currentPlayers} />
					</motion.div>
				</AnimatePresence>
			</div> : null
	);
};

const SeatingList = (props) => {
	const kickPlayer = (i) => {
		props.kick(i);
	};

	const players = props.playerList;
	const listOfPlayers = players.map((player, index) =>
		<li className="seating-list-item" key={player[0].toString()}>
			{index + 1}. {player[1].username} {props.isHost && (props.userUID !== player[0]) ? <button className="list-item-button" onClick={() => kickPlayer(index)}><ion-icon name="person-remove-outline"></ion-icon></button> : null}
		</li>
	);

	return (
		<>
			<motion.div className="seating-list">
				<ul>{listOfPlayers}</ul>
			</motion.div>
			<motion.div className="seating-disclaimer">
                LAST PLAYER ON THE LIST IS NEXT TO THE FIRST PLAYER
			</motion.div>
		</>
	);
};

export default SeatingArrangement;

import React from 'react';
import './loading.css';
import loadingGif from '../assets/pacman.gif';

const Loading = () => (
	<div className="flex-loading">
		<img alt="Loading icon" className="centered-img" src={loadingGif} />
	</div>
);

export default Loading;

import React,{useEffect, useState} from 'react';
import './leaderboard.css';
import {AnimatePresence, motion} from 'framer-motion';
import { getLeaderBoardTopTen } from '../backend/database';

const Leaderboard = (props) => {
	document.querySelector('meta[name="theme-color"]').setAttribute('content', '#1d3557');

	const [topTen, setTopTen] = useState(null);

	useEffect(() => {
		getLeaderBoardTopTen().then((res) => {
			setTopTen(res);
		});
	}, []);

	return (
		<motion.div className="app-container-leader">
			<AnimatePresence>
				<motion.div
					initial={{opacity: 0, y: 100}}
					animate={{opacity: 1, y: 0}}
					exit={{opacity: 0, y: 100}}
					className="leaderboard-list">
					<motion.div className="leaderboard-header">
						<button
							onClick={() => props.history.push('/')}
							className="close-leaderboard">
							<ion-icon class="exit-icon" className="exit-icon" name="close-outline">
							</ion-icon>
						</button>
						<div className="leaderboard-title">
							HALL OF DRINKERS 🍻
						</div>
					</motion.div>
					<div className="leaderboard-message">
							Drinks gotten when falling on purple &quot;Full Send&quot; cards will count towards your rankings in the leaderboards. Good luck!
					</div>
					{topTen !== null ? <LeaderboardList topTen={topTen}/> : null}
				</motion.div>
			</AnimatePresence>
		</motion.div>
	);
};

const LeaderboardList = (props) => {
	const container = {
		hidden: {opacity: 0},
		show: {
			opacity: 1,
			transition: {
				staggerChildren: 0.1
			}
		}
	};
	const item = {
		hidden: {opacity: 0},
		show: {opacity: 1}
	};

	const topTen = props.topTen;
	const listOfTopTen = topTen.map((player, index) =>
		<motion.li
			variants={item}
			className="leaderboard-item" key={index}>
			<div className="scrollable">
				{index + 1}. {player.username} ({player.drinks} {player.drinks === 1 ? 'drink' : 'drinks'})
			</div>
			{index < 3 ?
				<div className="leaderboard-top-3">
					<ion-icon class="medal" name="medal-outline"></ion-icon>
				</div>
				: null
			}
		</motion.li>
	);

	return (
		<>
			<motion.div className="leaderboard">
				<motion.ul
					variants={container}
					initial="hidden"
					animate="show"
					className="list-leaderboard">{listOfTopTen}</motion.ul>
			</motion.div>
		</>
	);
};

export default Leaderboard;

import firebase from './firebase.js';
import 'firebase/firestore';

const db = firebase.firestore();

export function addNewUserToDB(uid, email) {
	return db.collection('Users').doc(uid).set({
		'email': email,
		'drinksAmount': 0,
		'virusAmount': 0,
		'appearPublic': false,
		'onboarded': false,
		'created': Date.now(), // Caution, older users have new Date() as this attribute
	}).catch(error => {
		console.error('Firestore error: ', error);
	});
}

export function getUser(uid) {
	return db.collection('Users').doc(uid).get().then(result => {
		console.log(result.data());
	}).catch(error => {
		console.error('Firestore error: ', error);
	});
}

export async function getLeaderBoardTopTen() {
	return db.collection('Users').where('appearPublic', '==', true).orderBy('drinksAmount', 'desc').limit(25).get()
		.then(res => {
			const topTen = [];
			res.forEach(doc => {
				topTen.push({
					username: doc.data().username,
					drinks: doc.data().drinksAmount
				});
			});
			return topTen;
		}).catch(error => {
			console.error('Could not fetch the leaberboard: ', error);
			return null;
		});
}

export function checkIfUserIsOnboarded(uid) {
	return db.collection('Users').doc(uid).get()
		.then(doc => {
			if (doc.data() && doc.data().onboarded) {
				return true;
			}
			return false;
		})
		.catch(error => {
			console.error('Error getting username: ', error);
			return false;
		});
}

export async function checkIfUserHasUsername(uid) {
	let userNameExists = false;

	await db.collection('Users').doc(uid).get()
		.then(doc => {
			if (doc.data().username) {
				userNameExists = true;
			}
		});
	return userNameExists;
}

export async function checkIfUsernameIsTaken(username) {
	return db.collection('Users').where('username', '==', username).get()
		.then(result => {
			if (result.empty) {
				return false;
			} else {
				return true;
			}
		}).catch(error => {
			console.error('Error checking if username was taken: ', error);
		});
}

export async function updateUsername(uid, username, checkbox) {
	return db.collection('Users').doc(uid).update({
		'username': username,
		'appearPublic': checkbox,
		'onboarded': true,
	});
}

import React, { useEffect, useState } from 'react';
import Loading from '../components/loading.js';
import { checkIfUserIsOnboarded } from './database';
import UsernamePrompt from '../components/usernamePrompt';
import firebase from './firebase.js';

export const AuthContext = React.createContext({
	gameName: null,
	setGameName: () => {
	},
	showOnboarding: {
		show: false,
		backButton: false,
	},
	setShowOnboarding: () => {
	},
});

export const PopupContext = React.createContext({
	showPopup: false,
	text: '',
	setShowPopup: () => {
	},
	setText: () => {
	},
	time:  5000,
	setTime: () => {
        
	},
});

export const AuthProvider = ({ children }) => {
	const [gameName, setGameName] = useState(null);
    
	const [showPopup, setShowPopup] = useState(false);
	const [text, setText] = useState('');
	const [time, setTime] = useState(5000);
    
	const [currentUser, setCurrentUser] = useState(null);
	const [pending, setPending] = useState(true);
	const [showOnboarding, setShowOnboarding] = useState({
		show: false,
		backButton: false,
	});

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			setCurrentUser(user);
			setPending(false);

			if (user) {
				checkIfUserIsOnboarded(user.uid).then(onboarded => {
					if (!onboarded) {
						setShowOnboarding({
							show: true,
							backButton: false,
						});
					}
				});
			}
		});
	}, []);

	if (pending) {
		return <Loading />;
	}

	return (
		<AuthContext.Provider
			value={{
				currentUser,
				gameName,
				setGameName,
				setShowOnboarding,
			}}
		>
			<PopupContext.Provider
				value={{
					showPopup,
					text,
					time,
					setShowPopup,
					setText,
					setTime
				}}>
				{children}
				<UsernamePrompt showPrompt={showOnboarding} setShowPrompt={setShowOnboarding} />
			</PopupContext.Provider>
		</AuthContext.Provider>
	);
};

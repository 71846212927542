import React, { useContext, useEffect, useState } from 'react';
import './host.css';
import { AnimatePresence, motion } from 'framer-motion';
import { Players } from '../components/players';
import Loading from '../components/loading';
import firebase from '../backend/firebase';
import 'firebase/functions';
import 'firebase/database';
import { Link } from 'react-router-dom';
import { AuthContext, PopupContext } from '../backend/authProvider';
import { cardPackLength } from '../assets/cards';

const Host = (props) => {
	document.querySelector('meta[name="theme-color"]').setAttribute('content', '#a8dadc');

	const WELCOME_MESSAGE = 'Hey! Welcome to Drinks Away! Have fun, and please remember to drink responsibly! 💜🍺';
	const END_OF_GAME_INDEX = -3; // also update in functions/index.js

	const [roomID, setRoomID] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [playerList, setPlayerList] = useState(['']);
	const [showHostPrompt, setShowHostPrompt] = useState(false);
	const { gameName, setGameName } = useContext(AuthContext);
	const { setShowPopup, setText } = useContext(PopupContext);

	const handleNameInputChange = (e) => {
		setGameName(e.target.value);
	};

	const enterName = () => {
		if (gameName === null || gameName.length < 1) {
			setText('You\'re the host, I think your friends want to know who you are...\nWrite your name please!');
			setShowPopup(true);
			return;
		}

		setShowHostPrompt(false);
		setGameName(capitalizeFirstLetter(gameName));
	};

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
    
	const shuffle = (arr) => {
		let m = arr.length, t, i;
		while (m) {
			i = Math.floor(Math.random() * m--);

			t = arr[m];
			arr[m] = arr[i];
			arr[i] = t;
		}
		return arr;
	};

	useEffect(() => {
		if (!gameName) {
			setShowHostPrompt(true);
			return;
		}
	}, [gameName]);

	useEffect(() => {
		if (roomID === null && gameName !== null && !showHostPrompt) {
			// Shuffle cards and create string to store in db
			const cards = shuffle([...Array(cardPackLength).keys()]);
			cards.push(END_OF_GAME_INDEX);
			const cardsString = cards.join(',');

			firebase.functions().httpsCallable('createRoom')({
				username: gameName,
				cards: cardsString
			}).then(result => {
				setRoomID(result.data.roomID);
				firebase.functions().httpsCallable('deleteOldRooms')();
			}).catch(error => {
				console.error('Firebase cloud function error (createRoom): ', error);
			});
		}
	}, [roomID, gameName, showHostPrompt]);

	useEffect(() => {
		if (roomID === null) {
			return;
		}

		const reference = firebase.database().ref('Rooms/' + roomID + '/users');
		reference.on('value', snapshot => {
			if (!snapshot || !snapshot.val()) {
				return;
			}
			let users = snapshot.val();

			const newPlayerList = [];
			if (users) {
				for (let key in users) {
					if (users.hasOwnProperty(key)) {
						newPlayerList.push(users[key].username);
					}
				}
				setPlayerList(newPlayerList);
			}
		});

		return () => reference.off();
	}, [roomID]);

	const startGame = () => {
		if (playerList.length < 2) {
			setText('Maybe find some friends...\nYou need at least 2 players to start.');
			setShowPopup(true);
			return;
		}

		firebase.database().ref('Rooms/' + roomID).update({
			'currentCard': END_OF_GAME_INDEX + 1,
			'lastUpdate': Date.now(),
		}).then(() => {
			props.history.push('/game/' + roomID);
		}).catch(error => {
			console.error('Error starting game: ', error);
		});
	};

	return (
		<div className="app-container-host">
			<motion.div className={!showModal && !showHostPrompt ? 'host-grid' : 'host-grid-blur'}>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ scale: 1.1, opacity: 1 }}
					transition={{ duration: 1 }}
					className="header-flex">
					<Link to='/' className="back-host">
						<ion-icon name="arrow-back-outline">
						</ion-icon>
					</Link>
					<motion.div className="header-host"
						initial={{ opacity: 0 }}
						animate={{ scale: 1.1, opacity: 1 }}
						transition={{ duration: 1 }}>
						<p className="host-title">HOST A GAME</p>
					</motion.div>
				</motion.div>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 1 }}
					className="host-settings">
					<motion.div className="game-id-host">
                        Tell your friends to join with this game number:
						<div className="game-number">
							{roomID ? roomID : <Loading />}
						</div>
					</motion.div>
					<motion.div className="game-id-host">
                        See players in this lobby:
						<motion.button whileTap={{ scale: 0.9 }} onClick={() => setShowModal(!showModal)}
							className="game-number">
                            Players
						</motion.button>
					</motion.div>
					<div className="welcome-message">
						{WELCOME_MESSAGE}
					</div>
				</motion.div>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 1 }}
					className="start-game"
				>
					<motion.button
						whileTap={{ scale: 1.1 }}
						className="start-button"
						onClick={startGame}
					>
                        Start Game
					</motion.button>
				</motion.div>
			</motion.div>
			<AnimatePresence>
				{showHostPrompt && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="host-name-prompt"
						style={{
							position: 'fixed',
							top: '30%',
							left: '50%',
							transform: 'translate3d(-50%,0,0)'
						}}
					>
                                Add a username to host a game!
						<input onChange={handleNameInputChange} className="host-name-input"
							placeholder="Enter your name:" />
						<button className="link-from-prompt" onClick={() => {props.history.push('/');}}>
                                    BACK
						</button>
						<button className="link-from-prompt" onClick={enterName}>
                                    OK
						</button>
					</motion.div>
				)}
			</AnimatePresence>
			<AnimatePresence>
				<Players players={playerList} showModal={showModal} gameId={roomID} setShowModal={setShowModal} />
			</AnimatePresence>
		</div>
	);

};

export default Host;

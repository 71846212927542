import React, {useContext, useEffect} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import './popup.css';
import {PopupContext} from '../backend/authProvider';

const Popup = () => {
	const {showPopup, setShowPopup, text, setText, time, setTime} = useContext(PopupContext);

	useEffect(() => {
		if (showPopup) {
			setTimeout(() => {
				setShowPopup(false);
				setText('');
				setTime(5000);
			}, time);
		}
	}, [showPopup]);

	return (
		<>
			{showPopup ?
				<AnimatePresence exitBeforeEnter>
					<motion.div
						className="popup-container"
					>
						<motion.div
							initial={{y: 100}}
							animate={{y: 0}}
							exit={{y: 100}}
							className="popup">
							<p>{text}</p>
						</motion.div>
					</motion.div>
				</AnimatePresence>
				: null}
		</>
	);
};

export default Popup;

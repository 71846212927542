import React, {useEffect, useState} from 'react';
import './signInSuccess.css';
import {Link, Redirect} from 'react-router-dom';
import {motion} from 'framer-motion';

const SignInSuccess =  ()=> {
	document.querySelector('meta[name="theme-color"]').setAttribute('content', '#e63946');

	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setRedirect(true), 3000);

		return () => clearTimeout(timer);
	}, []);

	return redirect
		? <Redirect to="/"/>
		: <div className="app-container-redirect">
			<div className="grid-redirect">
				<div className="header-redirect">
					<Link to="/">
						<motion.p whileTap={{scale: 0.9}} className="title-redirect">DRINKS <br/>AWAY</motion.p>
					</Link>
				</div>
				<div id="space">
					<motion.div
						className="subHeader-redirect">
						SUCCESSFULLY SIGNED IN, PLEASE WAIT FOR REDIRECT
					</motion.div>
				</div>
			</div>
		</div>;
};

export default SignInSuccess;
